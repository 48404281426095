import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Divider, Flex, Form, Typography, Input, Button, DatePicker, Select, Row, Col, Upload, Tag, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import DataTable from '../../../components/data-table';
import CloseIcon from '../../../components/icon/CloseIcon';
import CommonPopconfirm from '../../../components/custom-popconfirm';
import EditIcon from '../../../components/icon/EditIcon';

import { createVacation, deleteVacation, getByIdVacation, getVacation, getVacationListData, setIsVacationSuccess, setIsVacationUpdating, updateVacation } from '../../settings/redux/slice';
import { getAllWorkerListing } from '../../../redux/slice/global';
import { getAdminLeavesListData, getApiData, getRequestedLeavesListData, getUserLeavesListData, getVacationUserLeaves } from '../redux/slice';

import UploadIcon from '../../../components/icon/UploadIcon';
import openNotification from '../../../components/notification';
import DocumentIcon from '../../../components/icon/DocumentIcon';

import GenerateVacationPDF from '../vactionPdf';
import { pdf } from '@react-pdf/renderer';

import '../../../styles/pages/new-vacation.scss';
import { black, lightPrimary, lightSecondary, lightYellow } from '../../../utils/theme/config';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const AddLeave = ({ }) => {
  const { t } = useTranslation();

  const [leaves, setLeaves] = useState([]);
  const dispatch = useDispatch();
  const { auth, profile, listing, vacation } = useSelector(state => state);
  const [stammUserListing, setStammUserListing] = useState([]);
  const [selectDateType, setSelectDateType] = useState('dateRange');
  const [selectedUser, setSelectedUser] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const [editId, setEditId] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [disabledDateType, setDisabledDateType] = useState(false)
  const [allUserLeavesList, setAllUserLeavesList] = useState([]);
  const [userLeavas, setUserLeavas] = useState([]);
  const [requestLeaveList, setRequestLeaveList] = useState([]);
  const [month, setMonth] = useState(dayjs().format('MM'));
  const [year, setYear] = useState(dayjs().format('YYYY'));
  const [fileList, setFileList] = useState([]);
  const [document, setDocument] = useState({});
  const [form] = Form.useForm();
  const [modalform] = Form.useForm();
  const [leavesDetails, setLeavesDetails] = useState({});
  const [open, setOpen] = useState(false);
  const downloadLinkRef = useRef();

  useEffect(() => {
    getLeavesData();
  }, [auth?.decodeToken?.organizer, month, year])

  const getLeavesData = () => {
    if (auth?.decodeToken?.organizer || auth?.decodeToken?._id) {
      dispatch(getAdminLeavesListData({
        month: month,
        year: year,
        organizer: auth?.decodeToken?.organizer
      }))
      dispatch(getUserLeavesListData({
        month: month,
        year: year,
        user: auth?.decodeToken?._id
      }))
    }
  }

  useEffect(() => {
    setAllUserLeavesList(vacation?.vacationList);
    setUserLeavas(vacation?.userVacationList)
  }, [vacation?.vacationList, vacation?.userVacationList])

  useEffect(() => {
    getRequestedLeaveData();
    getWorkerData();
  }, [auth?.decodeToken])

  const getRequestedLeaveData = () => {
    if (Object.keys(auth?.decodeToken)?.length > 0) {
      dispatch(getRequestedLeavesListData({
        organizer: auth?.decodeToken?.organizer
      }));
    }
  }

  // Get Leaves Data
  useEffect(() => {
    getVacationList();
  }, [auth?.decodeToken?.organizer]);

  // Get Vacation List
  const getVacationList = () => {
    if (auth?.decodeToken?.organizer) {
      dispatch(getVacationListData({
        filters: {
          leaveType: '',
          startDate: '',
          endDate: ''
        },
        user: null,
        organizer: auth?.decodeToken?.organizer
      }))
    }
  };

  useEffect(() => {
    setRequestLeaveList(vacation?.requestList);
  }, [vacation?.requestList])

  const getWorkerData = () => {
    if (Object.keys(auth?.decodeToken)?.length > 0) {
      dispatch(getAllWorkerListing(auth?.decodeToken?.organizer));
    }
  }

  const handleOnFinishExport = async (values) => {
    const leaves = await dispatch(getApiData({
      filters: {
        leaveType: values?.leaveType || '',
        startDate: dayjs(values?.startDate).format('DD.MM.YYYY'),
        endDate: dayjs(values?.endDate).format('DD.MM.YYYY')
      },
      user: values?.user,
      organizer: auth?.decodeToken?.organizer
    }))

    if (leaves.payload) {
      try {
        const blob = await pdf(<GenerateVacationPDF data={leaves.payload} />).toBlob();
        const blobUrl = URL.createObjectURL(blob);
        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = blobUrl;
          downloadLinkRef.current.download = "vacation.pdf";
          downloadLinkRef.current.click(); // Trigger the download using the ref
        }

        URL.revokeObjectURL(blobUrl);
        setOpen(false);
        modalform.resetFields();
      } catch (error) {
        openNotification({ message: error?.message, type: 'error' });
      }
    }
  }

  const handleClearExport = () => {
    modalform.resetFields();
  };

  // Set Vacation List
  useEffect(() => {
    setLeaves(profile.vacatonLists);
  }, [profile.vacatonLists]);

  useEffect(() => {
    setLeavesDetails({});
  }, [])

  // Get User List
  useEffect(() => {
    getStammUserLists();
  }, [auth?.decodeToken?.organizer])

  const getStammUserLists = () => {
    auth?.decodeToken?.organizer && dispatch(getAllWorkerListing(auth?.decodeToken?.organizer));
  };

  useEffect(() => {
    if (listing.listing?.allWorkers?.length > 0) {
      setStammUserListing(listing.listing?.allWorkers)
    }
  }, [listing.listing.allWorkers]);

  // Submit Data 
  const handleOnFinish = (values) => {
    if (editId) {
      dispatch(setIsVacationUpdating(false));
    }
    else {
      setIsCreated(true);
    }

    delete values?.dateType;

    if (selectDateType === 'dateRange') {
      const payload = {
        ...(values?.emergencyContactPerson) && { emergencyContactPerson: values?.emergencyContactPerson },
        leaveReason: values?.leaveReason ?? '',
        leaveType: values?.leaveType,
        isMultipleDate: false,
        leaveDateRange: {
          startDate: dayjs(values?.date[0]).format('DD.MM.YYYY'),
          endDate: dayjs(values?.date[1]).format('DD.MM.YYYY')
        },
        organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer,
        user: values?.user,
        uploadedFile: document
      }
      dispatch(getVacation(payload));
    }
    else {
      const payload = {
        ...(values?.emergencyContactPerson) && { emergencyContactPerson: values?.emergencyContactPerson },
        leaveReason: values?.leaveReason ?? '',
        leaveType: values?.leaveType,
        isMultipleDate: true,
        leaveDates: values?.date?.map((item) => dayjs(item).format('DD.MM.YYYY')?.toString()),
        organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer,
        user: values?.user,
        uploadedFile: document
      }
      dispatch(getVacation(payload));
    }
  }

  // Create and Upadte Vacation
  useEffect(() => {
    if (Object.keys(profile.vacation ?? {}).length > 0) {
      const formData = new FormData();

      formData.append('leaveReason', profile.vacation?.leaveReason)
      profile.vacation?.emergencyContactPerson && formData.append('emergencyContactPerson', profile.vacation?.emergencyContactPerson)
      formData.append('leaveType', profile.vacation.leaveType)
      formData.append('isMultipleDate', profile.vacation.isMultipleDate)
      formData.append('organizer', profile.vacation.organizer)
      formData.append('user', profile.vacation.user)
      Object.keys(profile.vacation?.uploadedFile ?? {}).length !== 0 && formData.append('uploadedFile', profile.vacation?.uploadedFile)
      Object.keys(profile.vacation?.leaveDateRange ?? {}).length !== 0 && Object.entries(profile.vacation?.leaveDateRange).forEach(([key, value]) => {
        formData.append(`leaveDateRange[${key}]`, value);
      });
      profile.vacation?.leaveDates?.length > 0 && formData.append('leaveDates', JSON.stringify(profile.vacation.leaveDates))

      if (!editId && isCreated) {
        dispatch(createVacation(formData));
      } else {
        if (profile.isVacationUpdating) {
          if (profile.vacation?.isMultipleDate) {
            setSelectDateType('multipleDate');
            form.setFieldsValue({ ...profile.vacation, dateType: 'multipleDate', date: profile.vacation?.leaveDates?.map((date) => dayjs(date)) });
          }
          else {
            setSelectDateType('dateRange');
            form.setFieldsValue({ ...profile.vacation, dateType: 'dateRange', date: [dayjs(profile?.vacation?.leaveDateRange?.startDate), dayjs(profile?.vacation?.leaveDateRange?.endDate)] });
          }
          setSelectedUser(profile.vacation?.user);
          dispatch(getVacationUserLeaves({ userId: profile.vacation?.user, year: dayjs().format('YYYY') }));
          setDisabledDateType(true)
          profile.vacation?.uploadedFile && setFileList([
            {
              uid: '-1',
              name: profile.vacation?.uploadedFile, // File name
              status: 'done',
              url: `${process.env.REACT_APP_API_URI}/vacation/${profile.vacation?.uploadedFile}`, // File URL
            },
          ])
        } else {
          formData.append('id', editId);
          dispatch(updateVacation(formData));
        }
      }
    }
    if (profile.isVacationSuccess) {
      handleClear();
      setEditId('');
      dispatch(getVacation({}));
      setIsCreated(false);
      dispatch(setIsVacationSuccess(false));
      setLeaveType('');
      setLeavesDetails({})
      getVacationList();
      setFileList([]);
      setDocument({});
      setSelectedUser('');
      setDisabledDateType(false);
    }
  }, [profile?.isVacationUpdating, profile?.vacation, profile?.isVacationSuccess]);

  // Clear all form data and state
  const handleClear = () => {
    form.resetFields();
    setSelectedUser('');
    setDocument({});
    setLeavesDetails({});
  }

  // Edit Leave 
  const handleEditLeave = (leaveId) => {
    setEditId(leaveId);
    dispatch(setIsVacationUpdating(true));
    dispatch(getByIdVacation(leaveId));
  }

  // Table columns
  const columns = [
    {
      title: t('organizer_common_user'),
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => {
        return (
          <Flex gap={8} align='center'>
            <span style={{ backgroundColor: record?.leaveType === 'sick' ? '#FFCFC5' : '#B7E7AF', height: '0.75rem', width: '0.75rem', borderRadius: '50%' }}></span>
            <Text>{record?.user}</Text>
          </Flex>
        )
      },
    },
    {
      title: t('organizer_profilesettings_vacations_leavedaterange'),
      dataIndex: 'leaveDateRange',
      key: 'leaveDateRange',
      render: (_, record) => {
        return (
          <Flex gap={8} align='center'>
            {
              record?.leaveDates?.length !== 0 ?
                <Text>{record.leaveDates.map(date => dayjs(date).format('DD.MM.YY')).join(', ')}</Text>
                :
                <Text>{`${dayjs(record?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(record?.leaveDateRange?.endDate).format('DD.MM.YY')}`}</Text>
            }
          </Flex>
        )
      },
    },
    {
      title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type'),
      dataIndex: 'leaveType',
      key: 'leaveType',
      render: (text) => <Text>{text?.charAt(0)?.toUpperCase() + text?.slice(1) || '-'}</Text>,
    },
    {
      title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_days'),
      dataIndex: 'leaveDays',
      key: 'leaveDays',
      render: (text) => <Text>{text} {t('organizer_common_days')}</Text>,
    },
    {
      title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason'),
      dataIndex: 'leaveReason',
      key: 'leaveReason',
      render: (text) => <Text>{text || '-'}</Text>,
    },
    {
      title: t('organizer_common_uploadedfile'),
      dataIndex: 'file',
      key: 'file',
      width: '200px',
      render: (_, record) => {
        return record?.uploadedFile ? <Flex align='center' gap={3}>
          <DocumentIcon />
          <Text>{record?.uploadedFile}</Text>
        </Flex>
          :
          '-'
      },
    },
    {
      title: t('organizer_common_status'),
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      render: (_, record) => {
        return <Fragment>
          {
            record.status?.toLowerCase() === 'approved' ?
              <Tag color={lightPrimary} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag> :
              record.status?.toLowerCase() === 'pending' ?
                <Tag color={lightYellow} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag> :
                <Tag color={lightSecondary} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag>
          }
        </Fragment>
      },
    },
    {
      title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
      key: 'action',
      width: '100px',
      render: (_, record) => {
        return (
          <Flex justify='center'>
            <Button
              type='text'
              className='padding-1'
              onClick={() => handleEditLeave(record?._id)}
            >
              <EditIcon role='button' />
            </Button>
            <CommonPopconfirm
              icon={null}
              title={t('organizer_profilesettings_vacations_deleteLeave')}
              description={t('organizer_profilesettings_vacations_deleteLeave_confirmdescription')}
              onConfirm={() => dispatch(deleteVacation({
                id: record?._id,
                filters: {
                  leaveType: '',
                  startDate: '',
                  endDate: ''
                },
                user: null,
                userId: auth?.decodeToken?._id,
                organizer: auth?.decodeToken?.organizer,
                year: dayjs().format('YYYY')
              }))}
              cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
            >
              <Button
                type='text'
                className='padding-1'>
                <CloseIcon role='button' />
              </Button>
            </CommonPopconfirm>
          </Flex >
        );
      },
    }
  ];

  useEffect(() => {
    setLeavesDetails(vacation?.userLeaves);
  }, [vacation?.userLeaves])

  // User select function
  const hanldeUserOnChange = (e) => {
    setSelectedUser(e);
    dispatch(getVacationUserLeaves({ userId: e, year: dayjs().format('YYYY') }));
    form.setFieldsValue({ emergencyContactPerson: undefined });
  }

  // File select function
  const onUploadChange = ({ file, fileList }) => {
    // Keep only the last uploaded file in the list
    if (fileList.length > 1) {
      fileList.splice(0, fileList.length - 1);
    }

    // Update the file list state
    setFileList(fileList);
    setDocument(file?.originFileObj)

    // Manually trigger validation to clear the error if the file is valid
    form.setFields([
      {
        name: 'files',
        errors: [], // Clear any previous errors
      },
    ]);
  };

  const validateFileType = (file) => {
    const allowedExtensions = [
      'pdf', 'jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', 'mp4', 'wmv', 'msg'
    ];

    // Extract the file extension
    let fileExtension = file.name.includes('.') ? file.name.split('.').pop() : file.type.split('/')[1];

    // Ensure no '+xml' is included in the extension (for SVG files)
    fileExtension = fileExtension.replace('+xml', '');

    // Check if the file extension is allowed
    const isValidExtension = allowedExtensions.includes(fileExtension.toLowerCase());

    if (!isValidExtension) {
      openNotification({
        type: 'error',
        message: 'Invalid file type!', // Direct error message
      });
      return Upload.LIST_IGNORE; // Ignore the file upload if invalid
    }
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      openNotification({ type: 'error', message: t('common_image_upload_error') });
      return Upload.LIST_IGNORE;
    }
    return true;
  }

  // Date select function
  const handleOnChangeDateType = (e) => {
    setSelectDateType(e);
    form.setFieldsValue({ date: '' });
  }

  // Disabled for Range Picker
  const disabledDate = (current) => {
    const isWeekend = current?.day() === 0 || current?.day() === 6; // 0 for Sunday, 6 for Saturday
    return isWeekend;
  };

  // Disabled for multiple picker
  const disabledMultipleDate = (current) => {
    const isWeekend = current?.day() === 0 || current?.day() === 6; // 0 for Sunday, 6 for Saturday
    return isWeekend;
  };

  const handleLeaveChange = (e) => {
    setLeaveType(e);
  }

  return (
    <Fragment>
      <Row
        justify='space-between'
        align='middle'
        className='padding-bottom-1'
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Flex justify='end' className='padding-1 rounded-1 bg-white'>
            <Button onClick={() => setOpen(true)}>{t('organizer_common_exportPDF')}</Button>

            <Modal
              open={open}
              closeIcon={<CloseIcon color={black} role='button' />}
              title={<Fragment><Title level={4} type='success'>{t('organizer_common_exportPDF')}</Title></Fragment>}
              footer={null}
              onCancel={() => { setOpen(false); modalform.resetFields() }}
              modalRender={(dom) => (
                <Form
                  layout='vertical'
                  form={modalform}
                  name='inventory-form'
                  requiredMark={(label, isRule) => {
                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                  }}
                  onFinish={(values) => handleOnFinishExport(values)}
                >
                  {dom}
                </Form>
              )}
            >
              <Row gutter={18}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='user'
                    label={t('organizer_common_user')}
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_user') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={t('organizer_common_user')}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={listing?.listing?.allWorkers}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='leaveType'
                    label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        {
                          label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                          value: 'formal'
                        },
                        {
                          label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                          value: 'sick'
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('organizer_common_startDate')}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_startDate') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('organizer_common_endDate')}
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_endDate') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className='margin-0'>
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button type='primary' danger block htmlType='button' onClick={handleClearExport}>{t('organizer_common_cancelButton')}</Button>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button type='primary' block htmlType='submit'>{t('organizer_common_exportPDF')}</Button>
                  </Col>
                </Row>
              </Form.Item>
            </Modal>
          </Flex>
        </Col>
      </Row>

      <Flex vertical gap={16} className='padding-1 new-vacation change-tag bg-white'>
        <Title type='success' level={4}>Add Leave</Title>
        <Divider className='margin-0' />

        <Form
          form={form}
          name='vacation'
          colon={true}
          requiredMark={(label, isRule) => {
            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
          }}
          initialValues={{ dateType: 'dateRange' }}
          layout='vertical'
          onFinish={handleOnFinish}
        >
          <Fragment>
            <Row gutter={18}>
              <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name='user'
                  label={t('organizer_common_user')}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_common_user') + ' ' + t('organizer_common_isrequired'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t('organizer_common_user')}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={hanldeUserOnChange}
                    options={stammUserListing}
                  />
                </Form.Item>
              </Col>
              {
                Object.keys(leavesDetails ?? {}).length > 0 &&
                <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24} >
                  <Row gutter={[16, 16]}>
                    <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
                      <Flex vertical justify='center' align='center'>
                        <Title level={3} type='success'>{leavesDetails?.totalLeaves ? (leavesDetails?.totalLeaves >= 10 ? leavesDetails?.totalLeaves : `0${leavesDetails?.totalLeaves}`) : '00'}</Title>
                        <Text>{t('organizer_common_total')} <br />{t('organizer_common_leaves')}</Text>
                      </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
                      <Flex vertical justify='center' align='center'>
                        <Title level={3} type='success'>{leavesDetails?.remainPaidLeaves ? (leavesDetails?.remainPaidLeaves >= 10 ? leavesDetails?.remainPaidLeaves : `0${leavesDetails?.remainPaidLeaves}`) : '00'}</Title>
                        <Text>{t('organizer_common_total')} {t('organizer_common_remain')} <br />{t('organizer_common_paid')} {t('organizer_common_leaves')}</Text>
                      </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
                      <Flex vertical justify='center' align='center'>
                        <Title level={3} className='blue-color'>{leavesDetails?.formalLeaves ? (leavesDetails?.formalLeaves >= 10 ? leavesDetails?.formalLeaves : `0${leavesDetails?.formalLeaves}`) : '00'}</Title>
                        <Text>{t('organizer_common_formal')} {t('organizer_common_leaves')}</Text>
                      </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
                      <Flex vertical justify='center' align='center'>
                        <Title level={3} className='yellow-color'>{leavesDetails?.sickLeaves ? (leavesDetails?.sickLeaves >= 10 ? leavesDetails?.sickLeaves : `0${leavesDetails?.sickLeaves}`) : '00'}</Title>
                        <Text>{t('organizer_common_sick')} {t('organizer_common_leaves')}</Text>
                      </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
                      <Flex vertical justify='center' align='center'>
                        <Title level={3} className='red-color'>{leavesDetails?.usedLeaves ? (leavesDetails?.usedLeaves >= 10 ? leavesDetails?.usedLeaves : `0${leavesDetails?.usedLeaves}`) : '00'}</Title>
                        <Text>{t('organizer_common_total')} {t('organizer_common_used')}<br />{t('organizer_common_leaves')}</Text>
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              }
              <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name='leaveType'
                  label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type') + ' ' + t('organizer_common_isrequired'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={handleLeaveChange}
                    options={[
                      {
                        label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                        value: 'formal'
                      },
                      {
                        label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                        value: 'sick'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name='emergencyContactPerson'
                  label={t('organizer_profilesettings_vacations_replacementcontactperson')}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder={t('organizer_profilesettings_vacations_replacementcontactperson')}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={stammUserListing?.filter((user) => user?.value !== selectedUser)}
                  />
                </Form.Item>
              </Col>
              {/* Date Selection Type */}
              <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name='dateType'
                  label={t('organizer_profilesettings_vacations_dateselectiontype')}
                >
                  <Select
                    showSearch
                    placeholder={t('organizer_profilesettings_vacations_dateselectiontype')}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={handleOnChangeDateType}
                    disabled={disabledDateType}
                    options={[
                      {
                        label: 'Date Range',
                        value: 'dateRange'
                      },
                      {
                        label: 'Multiple Date',
                        value: 'multipleDate'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              {/* Date Picker */}
              <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name='date'
                  label={t('organizer_profilesettings_vacations_leavedate')}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_common_daterangeerror'),
                    },
                  ]}
                >

                  {
                    selectDateType === 'dateRange' &&
                    <RangePicker
                      className='w-100'
                      format={'DD-MM-YYYY'}
                      disabledDate={disabledDate}
                    />
                  }
                  {
                    selectDateType === 'multipleDate' &&
                    <DatePicker
                      multiple
                      placeholder={t('organizer_common_date')}
                      maxTagCount='responsive'
                      size='middle'
                      disabledDate={disabledMultipleDate}
                      format={'DD-MM-YYYY'}
                      className='vacation-datepicker'
                    />
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              {/* Leave Reason */}
              <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name='leaveReason'
                  label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason')}
                >
                  <TextArea rows={3} placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason')} autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
              </Col>
              {/* Upload Files */}
              <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name='files'
                  label={t('organizer_common_uploadedfile')}
                  valuePropName='files'
                  getValueFromEvent={(e) => e && e.fileList}
                >
                  <Upload.Dragger
                    beforeUpload={validateFileType}
                    multiple={false}
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok');
                      }, 0);
                    }}
                    name='files'
                    fileList={fileList}
                    onChange={onUploadChange}
                  >
                    <Flex justify='center' align='center' gap={24}>
                      <UploadIcon size={30} />
                      <Text className='ant-upload-text' strong>{t('organizer_common_uploadedfilehere')}</Text>
                    </Flex>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                <Flex vertical gap={12} className='paddingY-3' justify='end'>
                  <Text type='secondary'>{t('organizer_common_uploadedfileheredescription')}</Text>

                  <Text type='secondary'>
                    {t('organizer_common_uploaddocumentsize')}
                  </Text>
                </Flex>
              </Col>
            </Row>
          </Fragment>
          <Flex justify='flex-end' gap={10} wrap={true}>
            <Button danger type='primary' htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
            <Button type='primary' htmlType='submit' loading={profile?.loadingVacation}>
              {leaveType?.toLowerCase() === t('organizer_common_sick')?.toLowerCase() ? t('organizer_common_submitButton') : t('organizer_profilesettings_vacations_requestleave')}
            </Button>
          </Flex>
        </Form>

        <Flex vertical>
          <Flex justify='flex-end' gap={8} className='padding-2'>
            <div style={{ paddingRight: '10px' }}>
              <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
              <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_sick')} {t('organizer_vacationmanagement_leave')}</b></span>
            </div>
            <div>
              <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
              <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_formal')} {t('organizer_vacationmanagement_leave')}</b></span>
            </div>
          </Flex>
          <DataTable
            columns={columns}
            scrollX={1000}
            showSorterTooltip={false}
            isPagination={false}
            data={leaves}
            rowKey={(record) => record?._id}
          />
        </Flex>
      </Flex>
      <a ref={downloadLinkRef} style={{ display: 'none' }}></a>
    </Fragment>
  );
};

export default AddLeave;
