import React, { useEffect, useState } from 'react';
import { Card, Col, Flex, Image, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import TimeTracker from './time-tracker/TimeTracker';
import openNotification from '../../components/notification';

import { getByDateTimeTracking } from './redux/slice';

import WorkingTime from '../../assets/images/working-hours.svg';
import BreakTime from '../../assets/images/break-time.svg';

import { primary } from '../../utils/theme/config';
import '../../styles/pages/dashboard.scss';
import { menuName } from '../../utils/constraints';

dayjs.extend(utc);

const { Title } = Typography;

const Dashboard = () => {
    const { t } = useTranslation();
    const [location, setLocation] = useState({});
    const [allowLocation, setAllowLocation] = useState(false);
    const { auth, dashboard } = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        getLocationPermission();
    }, []);

    const getLocationPermission = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handlePermissionSuccess, handlePermissionDecline);
        } else {
            openNotification({ type: 'error', message: 'Geolocation not supported' });
        }
    };

    const handlePermissionSuccess = (position) => {
        setLocation({
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude
        });
        setAllowLocation(false);
    };

    const handlePermissionDecline = () => {
        setAllowLocation(true);
        openNotification({ type: 'warning', message: t('organizer_common_location_error') });
    }

    useEffect(() => {
        if (auth.decodeToken?.organizer) {
            if (Object.keys(auth?.decodeToken ?? {}).length !== 0) {
                dispatch(getByDateTimeTracking({
                    user: auth?.decodeToken?._id,
                    organizer: auth?.decodeToken?.organizer,
                    date: dayjs.utc().startOf('day').toISOString()
                }))
            }
        }
    }, [auth]);

    // Find the Time Tracking module
    const timeTrackingModule = auth?.decodeToken?.permissions?.length && auth?.decodeToken?.permissions[0]?.menu?.find(item => item.menu === menuName.timeTracking && item.permissions.hasAccess);

    // If the module exists, check if the Work History child has access
    const workHistoryModule = timeTrackingModule?.children.find(child => child.menu === menuName.workHistory && child.permissions.hasAccess);

    const showTimeTracking =
        auth.decodeToken?.organizer &&
        timeTrackingModule &&
        workHistoryModule;

    return (
        <div className='dashboard-main padding-3'>
            {
                showTimeTracking ?
                    <Row>
                        <Col xxl={7} xl={10} lg={12} md={24}>
                            <Card title={null} className='border-green'>
                                <Row gutter={16}>
                                    <Col xs={24} sm={12}>
                                        <div className='rounded-2 bg-primary-light padding-1' style={{ border: `1px solid ${primary}` }}>
                                            <Flex vertical gap={16} align='center'>
                                                <Flex align='center' gap={16}>
                                                    <Image src={WorkingTime} height={35} preview={false} />
                                                    <Title level={5}>{t('organizer_common_workingtime')}</Title>
                                                </Flex>
                                                <Title level={4}>{dashboard?.timeTrackerList?.length !== 0 ? dashboard?.timeTrackerList[0]?.workingDuration : "00:00"}</Title>
                                            </Flex>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div className='rounded-2 bg-primary-light padding-1' style={{ border: `1px solid ${primary}` }}>
                                            <Flex vertical gap={16} align='center'>
                                                <Flex align='center' gap={16}>
                                                    <Image src={BreakTime} height={35} preview={false} />
                                                    <Title level={5}>{t('organizer_common_break')} {t('organizer_common_time')}</Title>
                                                </Flex>
                                                <Title level={4}>{dashboard?.timeTrackerList?.length !== 0 ? dashboard?.timeTrackerList[0]?.breakDuration : "00:00"}</Title>
                                            </Flex>
                                        </div>
                                    </Col>
                                </Row>
                                <TimeTracker location={location} allowLocation={allowLocation} />
                            </Card>
                        </Col>
                    </Row>
                    :
                    "Dashboard"
            }

        </div>
    )
}

export default Dashboard;
