import React, { Fragment, useEffect, useRef, useState } from 'react'

import { Flex, Button, Typography, DatePicker, Row, Col, Tooltip, Avatar, Table, Modal, Divider, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import BackIcon from '../../../components/icon/BackIcon';
import NextIcon from '../../../components/icon/NextIcon';
import CloseIcon from '../../../components/icon/CloseIcon';

import { black, primary } from '../../../utils/theme/config';
import { getAdminLeavesListData, getApiData, getRequestedLeavesListData, getUserLeaveListData, getUserLeavesListData } from '../redux/slice';
import openNotification from '../../../components/notification';
import GenerateVacationPDF from '../vactionPdf';
import { pdf } from '@react-pdf/renderer';
import { getVacationListData } from '../../settings/redux/slice';
import { getAllWorkerListing } from '../../../redux/slice/global';

const { Title, Text } = Typography;

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const datePickerRef = React.useRef(null);

  const handlePrevious = () => {
    onDateChange(selectedDate.subtract(1, 'month'));
  };

  const handleNext = () => {
    onDateChange(selectedDate.add(1, 'month'));
  };

  const handleDateChange = (date) => {
    setCalendarVisible(false);
    onDateChange(date || dayjs());
  };

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setCalendarVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Row align='middle' gutter={8} style={{ position: 'relative' }}>
      <Col>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button icon={<BackIcon size={24} color={primary} role='button' />} type='text' onClick={handlePrevious} style={{ margin: '0' }} />
          <span style={{ margin: '0 8px', fontSize: '18px', fontWeight: '600' }}>{selectedDate.format('MMM YYYY')}</span>
          <Button icon={<NextIcon size={30} color={primary} role='button' />} type='text' onClick={handleNext} style={{ margin: '0', marginTop: '8px' }} />
          {calendarVisible && (
            <DatePicker
              picker='month'
              open={calendarVisible}
              value={selectedDate}
              onChange={handleDateChange}
              variant='borderless'
              allowClear={false}
              suffixIcon={() => null}
              onClick={() => setCalendarVisible(true)}
              onBlur={() => setCalendarVisible(false)}
              style={{
                position: 'absolute',
                zIndex: 1000,
                marginTop: '8px',
                width: '0',
                color: 'transparent'
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

const AllLeaves = ({ }) => {
  const dispatch = useDispatch();
  const { auth, vacation, listing } = useSelector((state) => state);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalUserData, setModalUserData] = useState({});
  const { t } = useTranslation();
  const [month, setMonth] = useState(dayjs().format('MM'));
  const [year, setYear] = useState(dayjs().format('YYYY'));
  const [userLeavas, setUserLeavas] = useState([]);
  const [form] = Form.useForm();
  const [modalform] = Form.useForm();
  const [open, setOpen] = useState(false);
  const downloadLinkRef = useRef();

  useEffect(() => {
    getLeavesData();
  }, [auth?.decodeToken?.organizer, month, year])

  const getLeavesData = () => {
    if (auth?.decodeToken?.organizer || auth?.decodeToken?._id) {
      dispatch(getAdminLeavesListData({
        month: month,
        year: year,
        organizer: auth?.decodeToken?.organizer
      }))
      dispatch(getUserLeavesListData({
        month: month,
        year: year,
        user: auth?.decodeToken?._id
      }))
    }
  }

  useEffect(() => {
    setUserLeavas(vacation?.userVacationList)
  }, [vacation?.vacationList, vacation?.userVacationList])

  useEffect(() => {
    getRequestedLeaveData();
    getWorkerData();
  }, [auth?.decodeToken])

  const getRequestedLeaveData = () => {
    if (Object.keys(auth?.decodeToken)?.length > 0) {
      dispatch(getRequestedLeavesListData({
        organizer: auth?.decodeToken?.organizer
      }));
    }
  }

  // Get Leaves Data
  useEffect(() => {
    getVacationList();
  }, [auth?.decodeToken?.organizer]);

  // Get Vacation List
  const getVacationList = () => {
    if (auth?.decodeToken?.organizer) {
      dispatch(getVacationListData({
        filters: {
          leaveType: '',
          startDate: '',
          endDate: ''
        },
        user: null,
        organizer: auth?.decodeToken?.organizer
      }))
    }
  };

  const getWorkerData = () => {
    if (Object.keys(auth?.decodeToken)?.length > 0) {
      dispatch(getAllWorkerListing(auth?.decodeToken?.organizer));
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date || dayjs());
    setDate(dayjs(date).format('MM/DD/YYYY'));
    setYear(dayjs(date).format('YYYY'))
    setMonth(dayjs(date).format('MM'))
  };

  const renderTimeEntries = (leaveDatesByMonthYear) => {
    return (
      <div style={{ display: 'flex', width: '100%', position: 'relative', height: '30px' }}>
        {leaveDatesByMonthYear?.map((entry, index) => {
          const isWork = entry.type === 'formal';
          const color = isWork ? '#B7E7AF' : '#FFCFC5';

          const startDate = dayjs(entry.start, 'DD-MM-YYYY');
          const endDate = dayjs(entry.end, 'DD-MM-YYYY');

          // Calculate the days in the entry
          const entryDurationDays = endDate.diff(startDate, 'day') + 1;
          const startOffsetDays = startDate.date() - 1;

          const daysInMonth = dayjs().daysInMonth();
          const widthPercent = (entryDurationDays * 100) / daysInMonth;
          const startOffsetPercent = (startOffsetDays * 100) / daysInMonth;

          return (
            <Tooltip title={`${entry.days} days`} key={index}>
              <div
                style={{
                  backgroundColor: color,
                  width: `${widthPercent}%`,
                  marginLeft: `${startOffsetPercent}%`,
                  height: '30px',
                  lineHeight: '20px',
                  fontSize: '12px',
                  textAlign: 'center',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: 0,
                  borderRadius: '3px'
                }}
              >
                {/* days */}
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderTimeLabels = () => {
    const dateLabels = [];
    const daysInMonth = dayjs(date).daysInMonth();
    const currentYear = dayjs(date).year();
    const currentMonth = dayjs(date).month();

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDateTime = dayjs(`${currentYear}-${currentMonth + 1}-${day}`);
      const formattedDate = currentDateTime.format('DD');
      const dayName = currentDateTime.format('dddd').slice(0, 2);

      // Check if it's Saturday (Sa) or Sunday (Su)
      const isWeekend = dayName === 'Sa' || dayName === 'Su';

      dateLabels.push({ date: formattedDate, dayname: dayName, isWeekend });
    }

    return (
      <Row align='start' className='w-100' justify='space-between'>
        {dateLabels.map((label, index) => (
          <div
            key={index}
            style={{
              fontSize: '12px',
              width: `${100 / daysInMonth}%`,
              textAlign: 'center',
              padding: '5px 0',
              boxSizing: 'border-box',
              color: label.isWeekend ? '#ccc' : 'inherit',
              pointerEvents: label.isWeekend ? 'none' : 'auto',
            }}
          >
            {label.date} <br />
            {label.dayname}.
          </div>
        ))}
      </Row>
    );
  };

  useEffect(() => {
    setModalUserData(vacation?.userLeaveList);
  }, [vacation?.userLeaveList])


  const getUserLeave = (record) => {
    dispatch(getUserLeaveListData({
      user: record._id,
      organizer: auth?.decodeToken?.organizer,
      year: year
    }))
    showModal();
  }

  const columns = [
    {
      title: t('organizer_common_name'),
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      width: '250px',
      onCell: () => ({
        style: {
          minWidth: '190px',
          maxWidth: '250px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        },
      }),
      render: (text, record) => {
        return <>
          <Flex align='center' gap={15} onClick={() => getUserLeave(record)}>
            <Avatar
              src={`${process.env.REACT_APP_API_URI}/profiles/${record?.profilePicture}`}
              size={'large'}
            />
            <Flex vertical>
              <Text type='success' strong>{text}</Text>
              <Text>{record.phone}</Text>
            </Flex>
          </Flex >
        </>
      }
    },
    {
      title: '',
      onHeaderCell: () => ({
        style: { padding: 0, display: 'none' },
      }),
      key: 'time',
      children: [
        {
          title: renderTimeLabels(),
          dataIndex: 'building',
          key: 'building',
          render: (text, record) => (
            <div>
              {renderTimeEntries(record.leaveDatesByMonthYear)}
            </div>
          ),
        },
      ],
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalUserData({});
  };

  const handleOnFinish = async (values) => {
    const leaves = await dispatch(getApiData({
      filters: {
        leaveType: values?.leaveType || '',
        startDate: dayjs(values?.startDate).format('DD.MM.YYYY'),
        endDate: dayjs(values?.endDate).format('DD.MM.YYYY')
      },
      user: values?.user,
      organizer: auth?.decodeToken?.organizer
    }))

    if (leaves.payload) {
      try {
        const blob = await pdf(<GenerateVacationPDF data={leaves.payload} />).toBlob();
        const blobUrl = URL.createObjectURL(blob);
        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = blobUrl;
          downloadLinkRef.current.download = "vacation.pdf";
          downloadLinkRef.current.click(); // Trigger the download using the ref
        }

        URL.revokeObjectURL(blobUrl);
        setOpen(false);
        modalform.resetFields();
      } catch (error) {
        openNotification({ message: error?.message, type: 'error' });
      }
    }
  }

  const handleClear = () => {
    modalform.resetFields();
  };

  return (
    <Fragment>
      <Row
        justify='space-between'
        align='middle'
        className='padding-bottom-1'
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Flex justify='end' className='padding-1 rounded-1 bg-white'>
            <Button onClick={() => setOpen(true)}>{t('organizer_common_exportPDF')}</Button>

            <Modal
              open={open}
              closeIcon={<CloseIcon color={black} role='button' />}
              title={<Fragment><Title level={4} type='success'>{t('organizer_common_exportPDF')}</Title></Fragment>}
              footer={null}
              onCancel={() => { setOpen(false); modalform.resetFields() }}
              modalRender={(dom) => (
                <Form
                  layout='vertical'
                  form={modalform}
                  name='inventory-form'
                  requiredMark={(label, isRule) => {
                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                  }}
                  onFinish={(values) => handleOnFinish(values)}
                >
                  {dom}
                </Form>
              )}
            >
              <Row gutter={18}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='user'
                    label={t('organizer_common_user')}
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_user') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={t('organizer_common_user')}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={listing?.listing?.allWorkers}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='leaveType'
                    label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        {
                          label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                          value: 'formal'
                        },
                        {
                          label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                          value: 'sick'
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('organizer_common_startDate')}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_startDate') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('organizer_common_endDate')}
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_endDate') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className='margin-0'>
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button type='primary' danger block htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button type='primary' block htmlType='submit'>{t('organizer_common_exportPDF')}</Button>
                  </Col>
                </Row>
              </Form.Item>
            </Modal>
          </Flex>
        </Col>
      </Row>

      <Flex vertical gap={16}
        className='bg-white padding-1 rounded-1 w-100 vacation-table'
      >
        <Flex align='center' justify='space-between' wrap
        >
          <Title level={4}>{t('organizer_common_all')} {t('organizer_usermanagement_user_userListTitle')}</Title>
          <CustomDatePicker selectedDate={selectedDate} onDateChange={handleDateChange} />
          <Flex align='center' gap={8}>
            <div style={{ paddingRight: '10px' }}>
              <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
              <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_sick')} {t('organizer_vacationmanagement_leave')}</b></span>
            </div>
            <div>
              <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
              <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_formal')} {t('organizer_vacationmanagement_leave')}</b></span>
            </div>
          </Flex>
        </Flex>
        <Table
          columns={columns}
          dataSource={userLeavas}
          pagination={false}
          scroll={{ x: 2200 }}
          bordered
          className='new-vacation'
          rowKey={(record) => record?._id}
        />
        <Modal
          width={350}
          className='new-vacation'
          closeIcon={<CloseIcon role='button' color={black} />}
          title={
            <Flex align='center' gap={15} onClick={() => showModal()}>
              <Avatar
                src={`${process.env.REACT_APP_API_URI}/profiles/${modalUserData[0]?.profilePicture}`}
                size={'large'}
              />
              <Flex vertical>
                <Text type='success' strong>{modalUserData[0]?.user}</Text>
                <Text>{modalUserData[0]?.phone}</Text>
              </Flex>
            </Flex >
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Row className='padding-top-2'>
            <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
              <Title level={3} type='success'>{modalUserData[0]?.totalLeaves}</Title>
              <Text>{t('organizer_common_total')} <br /> {t('organizer_common_leaves')}</Text>
            </Col>
            <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
              <Title level={3} type='success' >{modalUserData[0]?.remainPaidLeaves}</Title>
              <Text className='text-center'>{t('organizer_common_total')} {t('organizer_common_remain')} <br /> {t('organizer_common_leaves')}</Text>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              <Title level={3} className='red-color'>{modalUserData[0]?.usedLeaves}</Title>
              <Text className='text-center'>{t('organizer_common_total')} {t('organizer_common_used')} <br /> {t('organizer_common_leaves')}</Text>
            </Col>
          </Row>
          {
            modalUserData[0]?.sickLeavesByYear?.length > 0 &&
            <Fragment>
              <Title level={5} className='padding-top-2'>{t('organizer_vacationmanagement_leave')} {t('organizer_common_type')} - {t('organizer_common_sick')} {t('organizer_vacationmanagement_leave')}</Title>
              <Divider />
            </Fragment>
          }
          {
            modalUserData[0]?.sickLeavesByYear?.map((sick, index) => {
              return <Fragment key={index}>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Text>{`${dayjs(sick?.start, 'DD-MM-YYYY').format('D MMM')} - ${dayjs(sick?.end, 'DD-MM-YYYY').format('D MMM')}` || '-'}</Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Flex justify='flex-end'>
                      <Text className='red-color'>{sick?.days || '-'} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
                    </Flex>
                  </Col>
                </Row>
              </Fragment>
            })
          }
          {
            modalUserData[0]?.sickLeavesByYear?.length > 0 &&
            <Fragment>
              <Divider />
              <Flex justify='flex-end'>
                <Text>{t('organizer_common_total')} - {modalUserData[0]?.sickLeavesByYear?.reduce((sum, item) => sum + item.days, 0)} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
              </Flex>
            </Fragment>
          }
          {
            modalUserData[0]?.formalLeavesByYear?.length > 0 &&
            <Fragment>
              <Title level={5} className='padding-top-2'>{t('organizer_vacationmanagement_leave')} {t('organizer_common_type')} - {t('organizer_common_formal')} {t('organizer_vacationmanagement_leave')}</Title>
              <Divider />
            </Fragment>
          }
          {
            modalUserData[0]?.formalLeavesByYear?.map((formal, index) => {
              return <Fragment key={index}>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Text>{`${dayjs(formal?.start, 'DD-MM-YYYY').format('D MMM')} - ${dayjs(formal?.end, 'DD-MM-YYYY').format('D MMM')}` || '-'}</Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Flex justify='flex-end'>
                      <Text className='red-color'>{formal?.days || '-'} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
                    </Flex>
                  </Col>
                </Row>
              </Fragment>
            })
          }
          {
            modalUserData[0]?.formalLeavesByYear?.length > 0 &&
            <Fragment>
              <Divider />
              <Flex justify='flex-end'>
                <Text>{t('organizer_common_total')} - {modalUserData[0]?.formalLeavesByYear?.reduce((sum, item) => sum + item.days, 0) || '0'} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
              </Flex>
            </Fragment>
          }
        </Modal>
      </Flex>
      <a ref={downloadLinkRef} style={{ display: 'none' }}></a>
    </Fragment >
  );
};

export default AllLeaves;
