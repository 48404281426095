import React, { Fragment, useEffect, useState } from 'react';

import { Col, Flex, Form, Input, Row, Tabs, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getOrganizerListing } from '../../redux/slice/global';

import AllUserTimeTracking from './all-user-time-tracking';
import IndividualTimeTracking from './individual-time-tracking';

import SearchIcon from '../../components/icon/SearchIcon';

const { Title } = Typography;

const TimeTracking = ({ permission }) => {
    const [form] = Form.useForm();

    const [search, setSearch] = useState('');
    const [userSearch, setUserSearch] = useState('')
    const { auth, listing } = useSelector(state => state);
    const dispatch = useDispatch();

    const [organizerLists, setOrganizerLists] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        // getUsersList();
    }, [search]);

    useEffect(() => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getOrganizerListing(auth?.decodeToken?.organizer));
        }
    }, [auth?.decodeToken])

    useEffect(() => {
        if (listing.listing?.organizer?.length > 0) {
            setOrganizerLists(listing.listing?.organizer);
        }
    }, [listing.listing?.organizer, auth])

    useEffect(() => {
        document.documentElement.scrollTop = document.documentElement.clientHeight;
        document.documentElement.scrollLeft = document.documentElement.clientWidth;
    }, []);

    return (
        <Fragment>
            <Flex vertical gap={24} className='tracking-table'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{t('organizer_timetracking_allusertimetracking')}</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder={t('organizer_common_search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} className='bg-white rounded-2'>
                        <Tabs
                            type='card'
                            size='large'
                            defaultActiveKey='1'
                            onChange={() => { form.resetFields(); setUserSearch('') }}
                            items={[
                                {
                                    label: t('organizer_timetracking_timetracking'),
                                    key: '1',
                                    children: <AllUserTimeTracking search={search} organizerLists={organizerLists} permission={permission} />,
                                },
                                {
                                    label: t('organizer_timetracking_individualtimetracking'),
                                    key: '2',
                                    children: <IndividualTimeTracking search={search} organizerLists={organizerLists} permission={permission} form={form} setUserSearch={setUserSearch} userSearch={userSearch} />,
                                },
                            ]}
                            className='padding-2'
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    );
};

export default TimeTracking;
