import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    timeTrackerList: [],
    loading: false,
    error: null,
    breakStartLoading: false,
    dayEndLoading: false,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setBreakStartLoading: (state, action) => {
            state.breakStartLoading = action.payload
        },
        setDayEndLoading: (state, action) => {
            state.dayEndLoading = action.payload
        },
        getTimeTrackings: (state, action) => {
            state.timeTrackerList = action.payload
        },
        setIsSuccess: (state, action) => {
            state.timeTracking = {};
            state.isSuccess = action.payload;
        },
    },
});

export const createTimeTracking = createAsyncThunk('createTimeTracking', (timeTrackingData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/timetracking/insert`, timeTrackingData?.payload).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setIsSuccess(true));
            dispatch(getByDateTimeTracking({ user: timeTrackingData?.payload?.user, organizer: timeTrackingData?.payload?.organizer, date: timeTrackingData?.date }))
            timeTrackingData?.setIsActive(timeTrackingData?.active);
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByDateTimeTracking = createAsyncThunk('getByIdTimeTracking', (timeTrackerUser, { dispatch }) => {
    axios.get(`/timetracking/date?user=${timeTrackerUser?.user}&organizer=${timeTrackerUser?.organizer}&date=${timeTrackerUser.date}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getTimeTrackings(response.data.record))
        } else {
            dispatch(getTimeTrackings([]));
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        dispatch(getTimeTrackings([]));
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateTimeTracking = createAsyncThunk('updateTimeTracking', (timeTrackingData, { dispatch }) => {
    dispatch(setLoading(true));
    timeTrackingData?.break ? dispatch(setBreakStartLoading(true)) : dispatch(setDayEndLoading(true));
    axios.post(`/timetracking/update`, timeTrackingData?.payload).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setIsSuccess(true));
            dispatch(setLoading(false));
            dispatch(setBreakStartLoading(false));
            dispatch(setDayEndLoading(false));
            timeTrackingData?.setIsBreak(timeTrackingData?.break);
            if (timeTrackingData?.setIsActive) {
                timeTrackingData?.setIsActive(false);
            }
            dispatch(getByDateTimeTracking({ user: timeTrackingData?.payload?.user, organizer: timeTrackingData?.payload?.organizer, date: timeTrackingData?.date }))
            openNotification({ message: response.data.message, type: 'success' });
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false));
        dispatch(setBreakStartLoading(false));
        dispatch(setDayEndLoading(false));
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, setIsUpdating, setIsSuccess, getTimeTrackings, setBreakStartLoading, setDayEndLoading } = slice.actions;

export default slice.reducer;
