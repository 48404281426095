import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Flex, Typography, Table, Button, Divider, Select, Modal, Form, Row, Col, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '../../../components/icon/CloseIcon';
import { black } from '../../../utils/theme/config';

import { approveLeave, getApiData, getRequestedLeavesListData, rejectLeave } from '../redux/slice';
import { getAllWorkerListing } from '../../../redux/slice/global';
import openNotification from '../../../components/notification';
import GenerateVacationPDF from '../vactionPdf';
import { pdf } from '@react-pdf/renderer';

const { Title, Text } = Typography;

const RequestLeave = ({ }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth, vacation, listing } = useSelector((state) => state);
  const [requestLeaveList, setRequestLeaveList] = useState([]);
  const [open, setOpen] = useState(false);
  const downloadLinkRef = useRef();
  const [modalform] = Form.useForm();

  useEffect(() => {
    getRequestedLeaveData();
    getWorkerData();
  }, [auth?.decodeToken])

  const getRequestedLeaveData = () => {
    if (Object.keys(auth?.decodeToken)?.length > 0) {
      dispatch(getRequestedLeavesListData({
        organizer: auth?.decodeToken?.organizer
      }));
    }
  }

  const getWorkerData = () => {
    if (Object.keys(auth?.decodeToken)?.length > 0) {
      dispatch(getAllWorkerListing(auth?.decodeToken?.organizer));
    }
  }

  const columns = [
    {
      title: t('organizer_common_user'),
      dataIndex: 'user',
      key: 'user',
      width: '100px',
      render: (_, record) => <Flex align='center' gap={10}>
        <div>
          <span style={{ backgroundColor: `${record.leaveType === 'sick' ? '#FFCFC5' : '#B7E7AF'}`, padding: '0px 10px', borderRadius: '10px' }}></span>
        </div>
        {`${record?.firstName} ${record?.lastName}`}
      </Flex>,
    },
    {
      title: t('organizer_vacationmanagement_leave_from_date'),
      dataIndex: 'leaveDateRange',
      key: 'leaveDateRange',
      width: '200px',
      render: (_, record) => {
        return (
          <Flex gap={8} align='center'>
            {
              record?.leaveDates?.length !== 0 ?
                <Text>{record.leaveDates?.map(date => dayjs(date).format('DD.MM.YY')).join(', ')}</Text>
                :
                <Text>{`${dayjs(record?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(record?.leaveDateRange?.endDate).format('DD.MM.YY')}`}</Text>
            }
          </Flex>
        )
      },
    },
    {
      title: t('organizer_vacationmanagement_leave') + t('organizer_common_type'),
      dataIndex: 'leaveType',
      width: '100px',
      key: 'leaveType',
      render: (text) => <Text>{text?.charAt(0)?.toUpperCase() + text?.slice(1)}</Text>,
    },
    {
      title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave') + t('organizer_common_days'),
      dataIndex: 'totalDays',
      key: 'totalDays',
      width: '200px',
      render: (text) => <Text>{text} {t('organizer_common_days')}</Text>,
    },
    {
      title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason'),
      dataIndex: 'leaveReason',
      key: 'leaveReason',
      width: '200px',
      render: (text) => <Text>{text || '-'}</Text>,

    },
  ];

  useEffect(() => {
    setRequestLeaveList(vacation?.requestList);
  }, [vacation?.requestList])

  const hanldeDeclineLeave = (record) => {
    dispatch(rejectLeave({
      id: record?._id,
      organizer: auth?.decodeToken?.organizer
    }))
  }

  const handleApproveLeave = (record) => {
    dispatch(approveLeave({
      id: record?._id,
      organizer: auth?.decodeToken?.organizer
    }))
  }

  const handleOnFinish = async (values) => {
    const leaves = await dispatch(getApiData({
      filters: {
        leaveType: values?.leaveType || '',
        startDate: dayjs(values?.startDate).format('DD.MM.YYYY'),
        endDate: dayjs(values?.endDate).format('DD.MM.YYYY')
      },
      user: values?.user,
      organizer: auth?.decodeToken?.organizer
    }))

    if (leaves.payload) {
      try {
        const blob = await pdf(<GenerateVacationPDF data={leaves.payload} />).toBlob();
        const blobUrl = URL.createObjectURL(blob);
        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = blobUrl;
          downloadLinkRef.current.download = "vacation.pdf";
          downloadLinkRef.current.click(); // Trigger the download using the ref
        }

        URL.revokeObjectURL(blobUrl);
        setOpen(false);
        modalform.resetFields();
      } catch (error) {
        openNotification({ message: error?.message, type: 'error' });
      }
    }
  }

  const handleClear = () => {
    modalform.resetFields();
  };

  return (
    <Fragment>
      <Row
        justify='space-between'
        align='middle'
        className='padding-bottom-1'
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Flex justify='end' className='padding-1 rounded-1 bg-white'>
            <Button onClick={() => setOpen(true)}>{t('organizer_common_exportPDF')}</Button>

            <Modal
              open={open}
              closeIcon={<CloseIcon color={black} role='button' />}
              title={<Fragment><Title level={4} type='success'>{t('organizer_common_exportPDF')}</Title></Fragment>}
              footer={null}
              onCancel={() => { setOpen(false); modalform.resetFields() }}
              modalRender={(dom) => (
                <Form
                  layout='vertical'
                  form={modalform}
                  name='inventory-form'
                  requiredMark={(label, isRule) => {
                    return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                  }}
                  onFinish={(values) => handleOnFinish(values)}
                >
                  {dom}
                </Form>
              )}
            >
              <Row gutter={18}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='user'
                    label={t('organizer_common_user')}
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_user') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={t('organizer_common_user')}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={listing?.listing?.allWorkers}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='leaveType'
                    label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        {
                          label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                          value: 'formal'
                        },
                        {
                          label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                          value: 'sick'
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('organizer_common_startDate')}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_startDate') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>

                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('organizer_common_endDate')}
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: t('organizer_common_endDate') + ' ' + t('organizer_common_isrequired'),
                      },
                    ]}
                  >
                    <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className='margin-0'>
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button type='primary' danger block htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button type='primary' block htmlType='submit'>{t('organizer_common_exportPDF')}</Button>
                  </Col>
                </Row>
              </Form.Item>
            </Modal>
          </Flex>
        </Col>
      </Row>

      <Flex vertical gap={16} className='bg-white padding-1 rounded-1 w-100'>
        <Flex vertical>
          <Title level={4} type='success' className='paddingX-2'>{t('organizer_common_all')} {t('organizer_vacationmanagement_leave')} {t('organizer_common_requests')}</Title>
          <Divider />
        </Flex>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <Fragment>
                <Flex vertical gap={16}>
                  {
                    record?.yearlyLeaves?.map((item, index) => {
                      return <Flex gap={15} justify='end' align='center'>
                        <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                          {t('organizer_common_year')} - <Text type='success'>{item?.year}</Text>
                        </Text>
                        <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                          {t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave')} - <Text type='success'>{item?.totalLeaves}</Text>
                        </Text>
                        <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                          {t('organizer_vacationmanagement_remainingleaves')} - <Text type='success'>{item?.remainingLeaves}</Text>
                        </Text>
                        {
                          index === record?.yearlyLeaves?.length - 1 ? (
                            <Fragment>
                              <Button danger type='primary' loading={vacation?.rejectLoading} onClick={() => hanldeDeclineLeave(record)}>{t('organizer_common_decline') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                              <Button type='primary' loading={vacation?.approveLoading} onClick={() => handleApproveLeave(record)}>{t('organizer_common_approve') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                            </Fragment>
                          )
                            :
                            (
                              <Fragment>
                                <Button style={{ visibility: 'hidden' }} danger type='primary' >{t('organizer_common_decline') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                                <Button style={{ visibility: 'hidden' }} type='primary' >{t('organizer_common_approve') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                              </Fragment>
                            )
                        }
                      </Flex>
                    })
                  }
                </Flex>
              </Fragment>
            ),
            rowExpandable: () => true,
            expandIcon: () => null,
          }}
          pagination={false}
          dataSource={requestLeaveList}
          scroll={{ x: 1000, y: null }}
          rowKey={'_id'}
          expandedRowKeys={requestLeaveList?.map((item) => item._id)}
          expandIconColumnIndex={-1}
        />
      </Flex>
      <a ref={downloadLinkRef} style={{ display: 'none' }}></a>
    </Fragment>
  );
};

export default RequestLeave;
