import dayjs from 'dayjs';
import { Button, Col, Row } from 'antd';
import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import openNotification from '../../../components/notification';

import { createTimeTracking, updateTimeTracking } from '../redux/slice';

const TimeTracker = ({ location, allowLocation }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const [isBreak, setIsBreak] = useState(false);
    const [workTime, setWorkTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const totalDaySeconds = 86400; // 24 hours
    const radius = 90;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - progress / 100);
    const { auth, dashboard } = useSelector(state => state);
    const dispatch = useDispatch();

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        if (dashboard?.timeTrackerList?.length !== 0) {
            const getLastTimeEntries = dashboard?.timeTrackerList[0]?.timeEntries[dashboard?.timeTrackerList[0]?.timeEntries?.length - 1];

            if (!getLastTimeEntries?.end) {
                // If the last entry is a break, you can set an additional flag:
                if (getLastTimeEntries?.type === 'break') {
                    setIsBreak(true);
                } else {
                    setIsBreak(false);
                }
                const startTimeStr = dashboard?.timeTrackerList[0]?.timeEntries[0]?.start;
                const startTime = dayjs(startTimeStr, 'HH:mm');
                const now = dayjs();
                const diffInSeconds = now.diff(startTime, 'second');
                setWorkTime(diffInSeconds);
                setIsActive(true);
            }
            else {
                setIsActive(false);
                const startTimeStr = dashboard?.timeTrackerList[0]?.timeEntries[0]?.start;
                const startTime = dayjs(startTimeStr, 'HH:mm');
                const endTime = dayjs(getLastTimeEntries?.end, 'HH:mm');
                const diffInSeconds = endTime.diff(startTime, 'second');
                setWorkTime(diffInSeconds);
            }
        }
        else {
            setWorkTime(0);
            setIsBreak(false);
            setIsActive(false);
        }
    }, [dashboard?.timeTrackerList, auth]);

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setWorkTime(prev => {
                    const newTime = prev + 1;
                    const newProgress = (newTime / totalDaySeconds) * 100;
                    setProgress(newProgress > 100 ? 100 : newProgress);
                    return newTime;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isActive, totalDaySeconds]);

    const handleStart = () => {
        if (allowLocation) {
            openNotification({ type: 'warning', message: t('organizer_common_location_error') });
            return;
        }
        dispatch(createTimeTracking({
            payload: {
                user: auth?.decodeToken?._id,
                organizer: auth?.decodeToken?.organizer,
                date: dayjs().format('MM/DD/YYYY'),
                timeEntries: [{
                    type: 'work',
                    start: dayjs().format('HH:mm'),
                    end: "",
                    lat: location?.latitude,
                    long: location?.longitude,
                }]
            },
            date: dayjs.utc().startOf('day').toISOString(),
            setIsActive,
            active: true
        }));
    };

    const getLastTimeEntries = () => {
        const currentTime = dayjs().format('HH:mm');

        // Create a shallow copy of each entry to avoid mutating the original objects.
        let updatedTimeEntries = dashboard?.timeTrackerList[0]?.timeEntries.map(entry => ({ ...entry })) || [];

        // If there's a previous entry, update its 'end' field in the new copy.
        if (updatedTimeEntries.length > 0) {
            const lastIndex = updatedTimeEntries.length - 1;
            updatedTimeEntries[lastIndex] = {
                ...updatedTimeEntries[lastIndex],
                end: currentTime,
            };
        }
        return updatedTimeEntries;
    }

    const handleBreakStart = () => {
        const currentTime = dayjs().format('HH:mm');

        const newTimeEntries = getLastTimeEntries();

        // Create a new break object.
        const breakEntry = {
            type: 'break',
            start: currentTime,
            end: "",
            lat: location?.latitude,
            long: location?.longitude,
        };

        const payload = {
            id: dashboard?.timeTrackerList[0]?._id,
            user: auth?.decodeToken?._id,
            organizer: auth?.decodeToken?.organizer,
            date: dayjs(dashboard?.timeTrackerList[0]?.date).format('MM/DD/YYYY'),
            timeEntries: [...newTimeEntries, breakEntry]
        }

        dispatch(updateTimeTracking({ payload, setIsBreak, break: true, date: dayjs.utc().startOf('day').toISOString(), }));
    };

    const handleBreakEnd = () => {
        const currentTime = dayjs().format('HH:mm');

        const newTimeEntries = getLastTimeEntries();

        // Create a new break object.
        const breakEntry = {
            type: 'work',
            start: currentTime,
            end: "",
            lat: location?.latitude,
            long: location?.longitude,
        };

        const payload = {
            id: dashboard?.timeTrackerList[0]?._id,
            user: auth?.decodeToken?._id,
            organizer: auth?.decodeToken?.organizer,
            date: dayjs(dashboard?.timeTrackerList[0]?.date).format('MM/DD/YYYY'),
            timeEntries: [...newTimeEntries, breakEntry]
        }

        dispatch(updateTimeTracking({ payload, setIsBreak, break: false, date: dayjs.utc().startOf('day').toISOString(), }));
    };

    const handleDayEnd = () => {
        const newTimeEntries = getLastTimeEntries();

        const payload = {
            id: dashboard?.timeTrackerList[0]?._id,
            user: auth?.decodeToken?._id,
            organizer: auth?.decodeToken?.organizer,
            date: dayjs(dashboard?.timeTrackerList[0]?.date).format('MM/DD/YYYY'),
            timeEntries: [...newTimeEntries]
        }

        dispatch(updateTimeTracking({ payload, setIsBreak, break: false, setIsActive, date: dayjs.utc().startOf('day').toISOString(), }));
    };

    return (
        <Fragment>
            {/* Circular Progress */}
            <div className="time-cricle">
                <svg className="circular-progress" width="256" height="256">
                    <circle
                        cx="128"
                        cy="128"
                        r={radius}
                        className="bg-circle"
                        strokeWidth="16"
                        fill="transparent"
                    />
                    <circle
                        cx="128"
                        cy="128"
                        r={radius}
                        className="pg-circle"
                        strokeWidth="16"
                        fill="transparent"
                        strokeDasharray={circumference}
                        strokeDashoffset={offset}
                        strokeLinecap="round"
                    />
                </svg>

                <div className='timer-display'>
                    <div className="timer-time">
                        {formatTime(workTime)}
                    </div>
                </div>
            </div>

            {/* Control Buttons */}
            <Row gutter={16} justify="center">
                {/* Day Start Button - Always visible when inactive */}
                {!isActive && (
                    <Col xs={24}>
                        <Button type="primary" onClick={handleStart} block loading={dashboard?.loading}>
                            Day Start
                        </Button>
                    </Col>
                )}

                {/* Break Start Button - Visible when active and not in break */}
                {isActive && !isBreak && (
                    <Col xs={12}>
                        <Button type="primary" onClick={handleBreakStart} block loading={dashboard?.breakStartLoading}>
                            Break Start
                        </Button>
                    </Col>
                )}

                {/* Break End Button - Visible when active and in break */}
                {isActive && isBreak && (
                    <Col xs={24}>
                        <Button type="primary" danger onClick={handleBreakEnd} block loading={dashboard?.loading}>
                            Break End
                        </Button>
                    </Col>
                )}

                {/* Day End Button - Always visible when active */}
                {isActive && !isBreak && (
                    <Col xs={12}>
                        <Button type="primary" danger onClick={handleDayEnd} block loading={dashboard?.dayEndLoading}>
                            Day End
                        </Button>
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};

export default TimeTracker;